<script>
const sendPiwikEvent = (el, isLink) => {
  return new Promise(function (resolve, reject) {
    // This is needed for block as a link
    const button = el.querySelectorAll('.button, .btn');
    try {
      const piwikEvent = {
        action: button.length > 0 ? button[0].innerText : isLink.innerText,
        category: el.getAttribute('data-piwik-event-name'),
        name: isLink.href
      };
      _paq.push(['trackEvent', piwikEvent.category, piwikEvent.action, piwikEvent.name]);
      resolve('Piwik event sent!');
    } catch {
      reject('Error sending Piwik event');
    }
  });
};

export default {
  bind(el) {
    el.addEventListener('click', event => {
      if (event) {
        event.preventDefault();
      }
      const isLink = event.target.closest('a[href]');
      if (!isLink) {
        return;
      }
      sendPiwikEvent(el, isLink)
        .then(msg => {
          window.location = isLink.href;
        })
        .catch(msg => {
          window.location = isLink.href;
        });
    });
  }
};
</script>
